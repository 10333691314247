<template>
  <div
    class="quickShip"
    :style="styleCustomize"
  >
    <sui_icon_qucikship_flat_24px
      :size="styleCustomize['icon-size']"
      :color="styleCustomize.color"
    />
    <span class="quickShipText">{{ text }}</span>
    <div 
      v-if="showLine" 
      class="quickShipLine" 
    ></div>
  </div>
</template>
<script setup>
import { sui_icon_qucikship_flat_24px } from '@shein-aidc/icon-vue3'

const props = defineProps({
  styleConfig: {
    type: Object,
    default: () => ({})
  },
  text: {
    type: String,
    default: 'quickShip'
  },
  showLine: {
    type: Boolean,
    default: false 
  }
})

const styleCustomize = {
  'color': '#FFF',
  'background-color': '#198055',
  'font-size': '12px',
  ...props.styleConfig
}

</script>
<style lang="less">
.quickShip{
  display: flex;
  width: fit-content;
  max-width: 100%;
  padding: 1px 3px;
  box-sizing: border-box;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  font-family: "SF Pro";
}
.quickShipText{
  font-style: italic;
  margin-left: 2px;
  padding-right: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // overflow: hidden;
  // display: -webkit-box;
  // -webkit-line-clamp: 1;
  // -webkit-box-orient: vertical;
  // word-break: break-all;
  // white-space: normal;
}
.quickShipLine {
  width: 1px;
  height: 14px;
  background: #8DC2AB;
  margin-left: 6px;
}
</style>
